.App {
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/* Appending styles to custom AWS Amplify components. */
.form-container {
  margin: 16px;
  background-color: var(--form-color);
  padding: 35px 40px;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.amplify-section-header {
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

a {
  color: var(--amazonOrange);
}
a:hover {
  cursor: pointer;
  text-decoration: underline;
}