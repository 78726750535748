body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.logo {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display:block;
}

.button {
  min-width: 153px;
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

.button:hover {
  background-color: var(--lightAmazonOrange);
}

.spinner {
    margin: 100px auto;
    width: 100px;
    text-align: center;
    font-size: 10px;
    height: 0px;
  }
  
  .spinner > div {
    background-color: var(--lightAmazonOrange);
    height: 80px;
    width: 6px;
    display: inline-block;
    margin: 3px;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }

.info {
    font-weight: bold;
    color: var(--color-gray);
}
.App {
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/* Appending styles to custom AWS Amplify components. */
.form-container {
  margin: 16px;
  background-color: var(--form-color);
  padding: 35px 40px;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.amplify-section-header {
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

a {
  color: var(--amazonOrange);
}
a:hover {
  cursor: pointer;
  text-decoration: underline;
}
