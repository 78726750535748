body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.logo {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display:block;
}

.button {
  min-width: 153px;
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

.button:hover {
  background-color: var(--lightAmazonOrange);
}
